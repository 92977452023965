


























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import store from "@/store/store";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { IUser, User } from "@/model/User";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton 
    } 
})

export default class DeclarationDialogue extends Vue {

    //
    // -- properties
    //

    private showDialogue: boolean = false;
    private signedBy: string = "";
    private user: User = new User();
    private isSaving: boolean = false;
    
    private get signedInUserName(): string {
         return store.state.signedInUser?.forename + ' ' + store.state.signedInUser?.surname;
    }

    //
    // -- methods
    //

    open() {
        this.reset();
        this.showDialogue = true;
    }

    private reset() {
        this.signedBy = "";
    }

    private async submit() {
        this.isSaving = true;

        const response: ISaveResponse = await apiClient.get(`/api/landlord/submitDeclaration`);

        const userData: IUser = await apiClient.get(`api/user/Load?id=${store.state.signedInUser?.id}`);
        this.user.update(userData);
        if (typeof (Storage) === "undefined") return;
        localStorage.setItem("user", JSON.stringify(this.user));

        toastr.success("Declaration Submitted");
        this.$emit("showInfoDialogue");
        this.showDialogue = false;
        this.isSaving = false;
    }
}
