






































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import DeclarationDialogue from "@/vue/components/DeclarationDialogue.vue";
import DeclarationDetailDialogue from "@/vue/components/DeclarationDetailDialogue.vue";
import InfoDialogue from "@/vue/components/InfoDialogue.vue";
import utils from "@/utilities/Utils";
import store from "@/store/store";
import { IDeclarationVM, DeclarationVM } from "@/model/Api/VM/DeclarationVM";
import { InfoType } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        DeclarationDialogue,
        DeclarationDetailDialogue,
        InfoDialogue
    }
})
export default class Declaration extends Vue {

    //
    // -- properties
    //

    confirmDisabled: boolean = true;
    acceptedTerms: boolean = false;
    private previousDeclaration: DeclarationVM = new DeclarationVM();

    private get hasActiveBuildings() {
        return store.state.signedInUser?.hasActiveBuildings;
    }

    //
    // -- methods
    //

    mounted() {
        this.loadPreviousDeclaration();
    }

    async loadPreviousDeclaration() {
        const declarationData: IDeclarationVM = await apiClient.get("api/landlord/loadDeclarationDetails");
        this.previousDeclaration.update(declarationData);
    }

    private downloadDeclaration() {
        // const documentUrl = "https://www.nationalcode.org/Handlers/Download.ashx?IDMF=6b189d2d-1ee1-4c8e-a0ba-f1548018c900";
        // window.open(documentUrl, "_blank", "noreferrer");
        const dialog: DeclarationDetailDialogue = this.$refs.declarationDetailDialogue as DeclarationDetailDialogue;
        dialog.open();
        this.confirmDisabled = false;
    }

    submit() {
        if (!this.hasActiveBuildings) {
            toastr.error("You need to add buildings before you can submit the declaration");
            return;
        }

        const dialog: DeclarationDialogue = this.$refs.declarationDialogue as DeclarationDialogue;
        dialog.open();
    }

    showInfoDialogue() {
        this.loadPreviousDeclaration();
        this.confirmDisabled = false;
        this.acceptedTerms = false;
        const dialog: InfoDialogue = this.$refs.infoDialogue as InfoDialogue;
        dialog.open(InfoType.DeclarationSigned);
    }
}
