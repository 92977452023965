import { mapData } from "@/utilities/DataMapping";
import utils from "@/utilities/Utils";

export interface IDeclarationVM {
    landlordID: string;
    submitted: Date;
    submittedBy: string;
    expiry: Date;
    canSubmitDeclaration: boolean;
}

export class DeclarationVM implements IDeclarationVM {

    constructor(data?: IDeclarationVM) {
        if (data) this.update(data);
    }

    update(data: IDeclarationVM) {
        mapData(data, { 
            root: () => this
        });
    }

    landlordID: string = utils.emptyGuidValue;
    submitted: Date = new Date(utils.emptyDateValue);
    submittedBy: string = "";
    expiry: Date = new Date(utils.emptyDateValue);
    canSubmitDeclaration: boolean = false;

    get isVisible() {
        return utils.hasDateValue(this.submitted);
    }
}
