



































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import { InfoType } from "@/model/Enums";

@Component({ components: { 
        ApiButton
    } 
})

export default class DeclarationDetailDialogue extends Vue {

    //
    // -- properties
    // 

    private showDialogue: boolean = false;

    //
    // -- methods
    //

    open() {
        this.showDialogue = true;
    }

    confirm() {
        this.showDialogue = false;
    }

    // downloadDoc() {
    //     const documentUrl = "https://www.nationalcode.org/Handlers/Download.ashx?IDMF=6b189d2d-1ee1-4c8e-a0ba-f1548018c900";
    //     window.open(documentUrl, "_blank", "noreferrer");
    // }
}
