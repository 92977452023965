


















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import { InfoType } from "@/model/Enums";

@Component({ components: { 
        ApiButton
    } 
})

export default class InfoDialogue extends Vue {

    //
    // -- properties
    // 

    private showDialogue: boolean = false;
    private infoType: InfoType = InfoType.None;

    get infoText() {
        if (this.infoType == InfoType.DeclarationSigned) {
            return "Your Declaration has now been accepted and the next stage of your application for membership of the National Code "
            + "is for you to complete the Company Self Assessment Questionnaire (SAQ). You will be notified as to when you can commence " 
            + "completion of the SAQ.  Full details can be found at https://www.nationalcode.org/self-assessment";
        } else if (this.infoType == InfoType.OSAQCompleted) {
            return "Thank you for completing the Company SAQ. The National Code Administrator will now consider, if relevant, "
            + "which of your buildings will be audited.  Once buildings and a verifier have been identified you will be contacted "
            + "and it will be necessary to complete a further self assessment for the specific building(s).";
        } else if (this.infoType == InfoType.SSAQCompleted) {
            return "Thank you for submitting your building self-assessment. This will now be reviewed by your appointed Verifier and considered as part of your scheduled visit.";
        } else {
            return "";
        }
    }

    //
    // -- methods
    //

    open(infoType: InfoType) {
        this.infoType = infoType;
        this.showDialogue = true;
    }

    confirm() {
        this.showDialogue = false;
    }
}
